<template>
    <div>
        <div class="row data-form">
            <div class="col-12 col-md-12">
                <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                    <div class="tabs-bottom-line"></div>
                    <b-tab active>
                        <template #title><i class="ri-message-line top-plus-2 mr-2"></i>{{ $t('student')+' & '+$t('thesis_and_project') }}</template>
                        <div class="row">
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('name')">
                                    {{formData.student ? formData.student.name : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-8 col-xl-9">
                                <b-form-group :label="$t('surname')">
                                    {{formData.student ? formData.student.surname : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('program')">
                                    {{ formData.student ? formData.student.program_name : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-8 col-xl-9">
                                <b-form-group :label="$t('student_number')">
                                    {{formData.student ? formData.student.student_number : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-12 col-xl-12">
                                <hr>
                                <h6>{{$t('thesis_and_project_info')}}</h6>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('title_title')">
                                    {{formData ? formData.title : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-8 col-xl-9">
                                <b-form-group :label="$t('application_date')">
                                    {{formData ? formData.created_at : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-12 col-xl-12">
                                <b-form-group :label="$t('summary')">
                                    {{formData ? formData.summary : '-'}}
                                </b-form-group>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-user-3-line top-plus-2 mr-2"></i>{{ $t('advisor') }}</template>
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <b-form-group :label="$t('advisor')">
                                    {{formData.advisor ? formData.advisor.name+' '+formData.advisor.surname : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('thesis_advisory')">
                                    {{formData ? formData.advisor_approved_thesis_count+'/14' : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('project_advisory')">
                                    {{formData ? formData.advisor_approved_project_count+'/16' : '-'}}
                                </b-form-group>
                            </div>
                            <div class="col-4 col-xl-3">
                                <b-form-group :label="$t('total_advisory')">
                                    {{formData ? formData.advisor_approved_project_count+formData.advisor_approved_thesis_count+'/30' : '-'}}
                                </b-form-group>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title><i class="ri-user-6-line top-plus-2 mr-2"></i>{{ $t('thesis_students') }}</template>
                        <div class="row">
                            <div class="col-12 col-xl-12">
                                <b-table striped hover :items="thesisStudents" :fields="thesisStudentFields"></b-table>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="col-12 col-md-12 d-flex justify-content-between" v-if="formData && formData.read_only===false">
                <b-button variant="primary" @click="changeStatus('approved')">{{$t('approve')}}</b-button>
                <b-button variant="danger" @click="changeStatus('rejected')">{{$t('decline')}}</b-button>
            </div>
            <CommonModal ref="confirmModal">
                <template v-slot:CommonModalTitle>
                    <div>{{$t('attention')}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="confirmForm">
                            <div class="row">
                                <div class="col-12 col-xl-12">
                                    <h6>{{$t('approve_or_reject_confirm')}}</h6>
                                </div>
                                <div class="col-12 col-xl-12" v-if="status == 'rejected'">
                                    <ValidationProvider name="reason" :rules="status == 'rejected' ? 'required' : ''" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('reason')">
                                            <b-form-textarea no-resize rows="4" class="h-80 h-auto-sm" maxlength="100"
                                                             v-model="reason"></b-form-textarea>
                                            <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-md-12">
                                    <b-button variant="primary" @click="saveStatus">{{$t('save')}}</b-button>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ThesisAndProjectService from "@/services/ThesisAndProjectService";
import moment from "moment";
import ApprovalStepService from "@/services/ApprovalStepService";
import CommonModal from "@/components/elements/CommonModal";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CommonModal
    },
    props: {
        id: {
            default: null,
            type: Number
        },
        metaTitle: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            tabIndex: 1,
            formData: {
                read_only: true
            },
            status: null,
            reason: null,
            thesisStudents: [],
            thesisStudentFields: [
                {
                    key: 'count_number',
                    label: this.$t('count_number')
                },
                {
                    key: 'student',
                    label: this.$t('student')
                },
                {
                    key: 'period',
                    label: this.$t('period')
                },
                {
                    key: 'approved_date',
                    label: this.$t('approved_date')
                },
                {
                    key: 'advisor',
                    label: this.$t('advisor')
                },
                {
                    key: 'approval_status',
                    label: this.$t('approval_status')
                },
                {
                    key: 'title',
                    label: this.$t('title')
                },
                {
                    key: 'program',
                    label: this.$t('program')
                }
            ]

        }
    },
    metaInfo() {
        return {
            title: this.metaTitle
        }
    },
    methods: {
        getData(Id){
            ThesisAndProjectService.show(Id).then(response => {
                this.formData = response.data.data
                this.formData.created_at = moment(this.formData.created_at).format('DD.MM.YYYY')
                let nmb = 1
                this.formData.advisor_approved_students.forEach((item) => {
                    this.thesisStudents.push({
                        count_number: nmb,
                        student: item.name+' '+item.surname,
                        period: item.academic_year+' '+item.semester_name,
                        approved_date: moment(item.created_at).format('DD.MM.YYYY'),
                        advisor: this.formData.advisor.name+' '+this.formData.advisor.surname,
                        approval_status: this.$t('approved'),
                        title: item.title,
                        program: item.program_name
                    })
                    nmb++
                })
            })
        },

        changeStatus(status){
            this.status = status
            this.$refs.confirmModal.$refs.commonModal.show()
        },

        async saveStatus(){
            const isValid = await this.$refs.confirmForm.validate();
            if (isValid) {
                let form = {
                    status : this.status
                }
                this.reason != null ? form.reason = this.reason : false

                ApprovalStepService.update(this.formData.active_step.id, form).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$refs.confirmModal.$refs.commonModal.hide()
                    this.$emit('hide')
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                            this.$refs.confirmModal.$refs.commonModal.hide()
                        }
                    }
                })
            }
        }
    },

    created() {

    },

    watch: {
        id: {
            handler: function (val) {
                this.getData(val)
            }
        }
    }
};
</script>
